import {createSlice} from '@reduxjs/toolkit';

const shopviewSlice = createSlice({
  name: 'shopview',
  initialState: {
    loading: true,
    vehiclesLookUp: {},
    serviceTasks: [],
    currOdometerDict: {},
    currEngineHoursDict: {},
    inspectionItemsLookUp: {},
    maintenanceItems: [],
    maintenanceSummary: {},
    historyItems: [],
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateShopviewData(state, action) {
      // Vehicle lookup
      const vehicles = action.payload.vehicles;
      // Create vehicles lookup based on vehicle serialNumber. Key: serialNumber, Value: vehicle document
      const vehiclesSerialLookUp = {};
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];

        if (typeof vehicle.serialNumber === 'undefined') {
          console.error('getVehiclesSerialLookUp vehicle does not have a serialNumber: ', vehicle);
          continue;
        }

        // Append service entry to lookup
        if (!vehiclesSerialLookUp.hasOwnProperty(vehicle.serialNumber)) {
          vehiclesSerialLookUp[vehicle.serialNumber] = vehicle;
        }
      }

      state.vehiclesLookUp = vehiclesSerialLookUp;

      // Service tasks
      state.serviceTasks = action.payload.serviceTasks;

      // Inspection Items
      const inspectionItems = action.payload.inspectionItems;

      // Create service entries lookup based on service inspection item ID. Key: id, Value: data
      const inspectionItemsLookUp = {};
      for (let i = 0; i < inspectionItems.length; i++) {
        const inspectionItem = inspectionItems[i];
        // Append service entry to lookup
        if (!inspectionItemsLookUp.hasOwnProperty(inspectionItem.id)) {
          inspectionItemsLookUp[inspectionItem.id] = inspectionItem;
        }
      }

      state.inspectionItemsLookUp = inspectionItemsLookUp;
    },
    updateMaintenanceData(state, action) {      
      const maintenanceData = action.payload;
      state.maintenanceItems = maintenanceData.maintenanceItems;
      state.maintenanceSummary = maintenanceData.maintenanceSummary;
      state.historyItems = maintenanceData.historyItems;
      state.currOdometerDict = maintenanceData.odoByVehiclesn;
      state.currEngineHoursDict = maintenanceData.engHrByVehiclesn;
    },
  },
});

function getServiceData() {
  return async (dispatch, getState) => {
    dispatch(shopviewSlice.actions.updateLoading(true));

    const getMaintenanceDataRequest = fetch('/shopview/getMaintenanceData', {cache: 'no-store'});    

    // Get vehicle information
    const getVehiclesRequest = fetch('/shopview/getVehicles', {cache: 'no-store'});

    // Get service information
    const getServiceTasksRequest = fetch('/shopview/getServiceTasks', {cache: 'no-store'});

    // Get inspection items
    const getInspectionItemsRequest = fetch('/getInspectionItems', {cache: 'no-store'});

    // Make all calls
    const [
      getMaintenanceDataResponse,
      getVehiclesResponse,
      getServiceTasksResponse,
      getInspectionItemsResponse,
    ] = await Promise.all([
      getMaintenanceDataRequest,
      getVehiclesRequest,
      getServiceTasksRequest,
      getInspectionItemsRequest,
    ]);

    // Get maintenance data
    const maintenanceData = await getMaintenanceDataResponse.json();      
    
    // Get vehicles collection
    const vehicles = await getVehiclesResponse.json();

    // Get service tasks collections
    const getServiceTasksResult = await getServiceTasksResponse.json();
    const serviceTasks = getServiceTasksResult.data;

    // Get Inspection Items
    const inspectionItems = await getInspectionItemsResponse.json();

    dispatch(
      shopviewSlice.actions.updateShopviewData({
        vehicles,
        serviceTasks,
        inspectionItems,
      })
    );
    dispatch(
      shopviewSlice.actions.updateMaintenanceData(maintenanceData)
    );
    dispatch(shopviewSlice.actions.updateLoading(false));
  };
}

export {getServiceData};
export default shopviewSlice.reducer;
