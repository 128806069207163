import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {numberWithCommas, unitsDisplayConversion, sortVehicleNamesHelper} from '../../../app/utils';
import {HistoryTable} from './HistoryTable';

function HistoryTab(props) {
  const loading = useSelector((state) => {
    return state.shopview.loading;
  });
  const historyItems = useSelector((state) => {
    return state.shopview.historyItems;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [tableData, setTableData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [units, setUnits] = useState('km');

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    getHistory();
  }, [historyItems]);

  return (
    <div className='tab-wrapper'>
      <div className='row my-2'>
        <div className='row ml-auto mx-0 col-md-8 col-xl-4'>
          <button type='button' className='btn border-dark btn-light col-6' onClick={downloadCSV}>
            Download CSV
          </button>
          <button
            type='button'
            className='btn border-dark btn-light col-6'
            data-toggle='modal'
            data-target='#shopview-add-history-modal'
          >
            Add History
          </button>
        </div>
      </div>
      <HistoryTable tableData={tableData} filterOptions={filterOptions} loading={loading} />
    </div>
  );

  function downloadCSV() {
    const csvList = [];
    tableData.map((rowData, index) => {
      let exportOdoOverValue =
        rowData.odometerOver || rowData?.odometerOver == 0 ? unitsDisplayConversion(rowData.odometerOver, units) : '';
      let exportEngOverValue = rowData.engineHoursOver;

      if (
        exportOdoOverValue == '' &&
        rowData.serviceEntry?.parentTask?.odometer_interval != '' &&
        typeof rowData.serviceEntry?.parentTask?.odometer_interval != 'undefined'
      ) {
        exportOdoOverValue = 0;
      }

      if (
        exportEngOverValue == '' &&
        rowData.serviceEntry?.parentTask?.engine_hours_interval != '' &&
        typeof rowData.serviceEntry?.parentTask?.engine_hours_interval != 'undefined'
      ) {
        exportEngOverValue = 0;
      }

      csvList.push([
        [
          `\"${rowData.vehicle}\"`,
          `\"${rowData.task}\"`, // Used to ignore commas and breaks
          unitsDisplayConversion(rowData.odometer, units),
          exportOdoOverValue,
          rowData.engineHours,
          exportEngOverValue,
          rowData.dateCompleted,
          rowData.timeOver,
          rowData.timeUnits,
          rowData.workOrder,
          rowData.invoice,
          rowData.laborCost,
          rowData.partsCost,
          rowData.laborTime,
          `\"${rowData.supplier}\"`,
          `\"${rowData.reviewedBy}\"`,
          `\"${rowData.notes}\"`,
        ],
      ]);
    });

    // eslint-disable-next-line max-len
    let csv = `Equipment,Service,Odometer When Complete (${units}),Odometer Over Target (${units}), Engine Hours When Complete (hr),Engine Hours Over Target (hr),Date Completed,Time Over Target,Time Over Units,Work Order,Invoice Number,Labor Cost ($),Parts Cost ($),Labor Time (hrs),Supplier,Reviewed By,Notes\n`;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'history.csv';
    hiddenElement.click();
  }

  function getHistory() {
    const rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsTask = [];

    historyItems.forEach((historyItem) => {
      const serviceEntry = historyItem.serviceEntry;

      // Create complete string
      let completedString = '';
      completedString += serviceEntry.odometer === ''
          ? ''
          : `Odometer When Completed: ${numberWithCommas(
              unitsDisplayConversion(serviceEntry.odometer, units)
            )} ${units}`;
      completedString += serviceEntry.engine_hours === ''
          ? ''
          : `<br>Engine Hours When Completed: ${numberWithCommas(Math.round(serviceEntry.engine_hours))} hrs`;

      rowList.push({
        serviceEntry: serviceEntry,
        vehicle: historyItem.vehicleName,
        vehicleSN: historyItem.vehicleSN,
        task: historyItem.associatedServiceTask.name,
        completedString: completedString,
        odometer: serviceEntry.odometer,
        engineHours: serviceEntry.engine_hours,
        dateCompleted: serviceEntry.date.slice(0, 10),
        workOrder: serviceEntry.work_order,
        invoice: serviceEntry.invoice,
        laborCost: serviceEntry.labor_cost,
        partsCost: serviceEntry.parts_cost,
        laborTime: serviceEntry.labor_time,
        supplier: serviceEntry.supplier,
        reviewedBy: serviceEntry.reviewed_by,
        notes: serviceEntry.notes,
        user: serviceEntry.updated_by,
        timeOver: serviceEntry.timeOver,
        timeUnits: serviceEntry.timeUnits,
        odometerOver: serviceEntry.odometerOver,
        engineHoursOver: serviceEntry.engineHoursOver,
      });

      // Update filter options
      filterOptionsVehicle.push(historyItem.vehicleName);
      filterOptionsTask.push(historyItem.associatedServiceTask.name);
    });

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsTask.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], tasks: [...new Set(filterOptionsTask)]};
    });

    rowList.sort((a, b) => {
      return b.dateCompleted.localeCompare(a.dateCompleted) ||
        (b.engineHours - a.engineHours) ||
        (b.odometer - a.odometer);
    });

    setTableData(rowList);
  }
}

export {HistoryTab};
