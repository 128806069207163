import {createSlice} from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: true,
    getSettingsTrigger: false,
    implementsBulkEditMode: false,
    tasksBulkEditMode: false,
    inspectionItemsBulkEditMode: false,
    activeDevices: {
      'clearpath': {},
      'geotab': {},
    },
    implementSNDict: {},
    implementsBulkEditErrors: {},
    assetLabelsBulkEditErrors: {},
    inspectionItemsBulkEditErrors: {},
    tasksBulkEditErrors: {},
    taskConfigDict: {},
    devicesList: [],
    databaseUsers: [],
    settingsGroups: {},
    vehicles: [],
    vehiclesBulkEditErrors: {},
    vehiclesUnfiltered: [],
    zones: {
      blocks: [],
      fields: [],
      regions: [],
    },
    labels: [],
    assetLabelsDict: {},
    inspectionItemsDict: {},
    btTags: [],
    btHubs: [],
    inspectionItems: [],
    tableLoading: {
      'task': true,
      'implements': true,
      'assetLabels': true,
      'inspectionItems': true,
    },
    bulkEditAssetLabels: {},
    bulkEditVehicles: {},
    bulkEditImplements: {},
    bulkEditTasks: {},
    bulkEditInspectionItems: {},
    reportConfigsDict: {},
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateTableLoading(state, action) {
      state.tableLoading = {
        ...state.tableLoading,
        [action.payload.table]: action.payload.status,
      };
    },
    setImplementsBulkEditMode(state, action) {
      state.implementsBulkEditMode = action.payload;
    },
    setTasksBulkEditMode(state, action) {
      state.tasksBulkEditMode = action.payload;
    },
    setAssetLabelsBulkEditMode(state, action) {
      state.assetLabelsBulkEditMode = action.payload;
    },
    setInspectionItemsBulkEditMode(state, action) {
      state.inspectionItemsBulkEditMode = action.payload;
    },
    updateDevices(state, action) {
      const devicesActive = JSON.parse(JSON.stringify(action.payload));
      const devicesList = [];
      Object.keys(action.payload).forEach(function (source) {
        Object.keys(action.payload[source]).forEach(function (key) {
          devicesList.push(action.payload[source][key]);
          if (!action.payload[source][key].active) {
            delete devicesActive[source][key];
          }
        });
      });

      state.devicesList = devicesList;
      state.activeDevices = devicesActive;
    },
    updateDatabaseUsers(state, action) {
      state.databaseUsers = action.payload;
    },
    updateSettingsGroups(state, action) {
      state.settingsGroups = action.payload;
    },
    updateVehiclesData(state, action) {
      state.vehicles = action.payload;
    },
    setVehiclesBulkEditErrors(state, action) {
      state.vehiclesBulkEditErrors = action.payload;
    },
    setImplementsBulkEditErrors(state, action) {
      state.implementsBulkEditErrors = action.payload;
    },
    setTasksBulkEditErrors(state, action) {
      state.tasksBulkEditErrors = action.payload;
    },
    setInspectionItemsBulkEditErrors(state, action) {
      state.inspectionItemsBulkEditErrors = action.payload;
    },
    setAssetLabelsBulkEditErrors(state, action) {
      state.assetLabelsBulkEditErrors = action.payload;
    },
    updateVehiclesUnfilteredData(state, action) {
      state.vehiclesUnfiltered = action.payload;
    },
    updateTaskConfigDict(state, action) {
      state.taskConfigDict = action.payload;
    },
    updateImplementSNDict(state, action) {
      state.implementSNDict = action.payload;
    },
    updateReportConfigsDict(state, action) {
      state.reportConfigsDict = action.payload;
    },
    updateZonesData(state, action) {
      const zones = action.payload;
      zones.blocks.sort((a, b) => {
        return a.block_name.localeCompare(b.block_name);
      });
      zones.fields.sort((a, b) => {
        return a.field_name.localeCompare(b.field_name);
      });
      zones.regions.sort((a, b) => {
        return a.region_name.localeCompare(b.region_name);
      });
      state.zones = action.payload;
    },
    updateLabelsData(state, action) {
      state.labels = action.payload;
    },
    updateBtTagsData(state, action) {
      state.btTags = action.payload;
    },
    updateBtHubsData(state, action) {
      state.btHubs = action.payload;
    },
    updateInspectionItemsData(state, action) {
      const inspectionItems = [];
      action.payload.forEach((item) => {
        if (!item.archived) {
          inspectionItems.push(item);
        }
      });
      state.inspectionItems = inspectionItems;
    },
    triggerGetSettingsData(state) {
      state.getSettingsTrigger = !state.getSettingsTrigger;
    },
    updateBulkEditVehicles(state, action) {
      const tempData = {
        ...(Object.hasOwnProperty.call(state.bulkEditVehicles, action.payload.vehicleSN)
          ? state.bulkEditVehicles[action.payload.vehicleSN]
          : {}),
        ...action.payload.data,
      };
      state.bulkEditVehicles = {
        ...state.bulkEditVehicles,
        [action.payload.vehicleSN]: tempData,
      };
    },
    updateBulkEditImplements(state, action) {
      const tempData = {
        ...(Object.hasOwnProperty.call(state.bulkEditImplements, action.payload.serialNumber)
          ? state.bulkEditImplements[action.payload.serialNumber]
          : {}),
        ...action.payload.data,
      };
      state.bulkEditImplements = {
        ...state.bulkEditImplements,
        [action.payload.serialNumber]: tempData,
      };
    },
    updateBulkEditInspectionItems(state, action) {
      let tempData;
      // Append newly added vehicle type
      if (
        action.payload.hasOwnProperty('data') &&
        action.payload.data.hasOwnProperty('vehicleType') &&
        !Array.isArray(action.payload.data.vehicleType)
      ) {
        tempData = {
          ...(Object.hasOwnProperty.call(state.bulkEditInspectionItems, action.payload.id)
            ? state.bulkEditInspectionItems[action.payload.id]
            : {}),
        };
        if (!tempData.vehicleType.includes(action.payload.data.vehicleType)) {
          tempData.vehicleType.push(action.payload.data.vehicleType);
        }
      }
      //  Delete newly deleted vehicle type
      else if (
        action.payload.hasOwnProperty('data') &&
        action.payload.data.hasOwnProperty('vehicleType') &&
        action.payload.data.hasOwnProperty('deletedVal')
      ) {
        tempData = {
          ...(Object.hasOwnProperty.call(state.bulkEditInspectionItems, action.payload.id)
            ? state.bulkEditInspectionItems[action.payload.id]
            : {}),
        };
        const index = tempData.vehicleType.indexOf(action.payload.data.deletedVal);
        if (index > -1) {
          tempData.vehicleType.splice(index, 1);
        }
      }
      //  Normal update
      else {
        tempData = {
          ...(Object.hasOwnProperty.call(state.bulkEditInspectionItems, action.payload.id)
            ? state.bulkEditInspectionItems[action.payload.id]
            : {}),
          ...action.payload.data,
        };
      }

      state.bulkEditInspectionItems = {
        ...state.bulkEditInspectionItems,
        [action.payload.id]: tempData,
      };
    },
    updateBulkEditTasks(state, action) {
      const tempData = {
        ...(Object.hasOwnProperty.call(state.bulkEditTasks, action.payload.taskId)
          ? state.bulkEditTasks[action.payload.taskId]
          : {}),
        ...action.payload.data,
      };
      state.bulkEditTasks = {
        ...state.bulkEditTasks,
        [action.payload.taskId]: tempData,
      };
    },
    updateBulkEditAssetLabels(state, action) {
      const tempData = {
        ...(Object.hasOwnProperty.call(state.bulkEditAssetLabels, action.payload.id)
          ? state.bulkEditAssetLabels[action.payload.id]
          : {}),
        ...action.payload.data,
      };
      state.bulkEditAssetLabels = {
        ...state.bulkEditAssetLabels,
        [action.payload.id]: tempData,
      };
    },
    initializeBulkEditImplements(state, action) {
      state.bulkEditImplements = action.payload;
    },
    initializeBulkEditTasks(state, action) {
      state.bulkEditTasks = action.payload;
    },
    initializeBulkEditVehicles(state, action) {
      state.bulkEditVehicles = action.payload;
    },
    initializeBulkEditAssetLabels(state, action) {
      state.assetLabelsDict = action.payload;
      state.bulkEditAssetLabels = action.payload;
    },
    initializeBulkEditInspectionItems(state, action) {
      state.inspectionItemsDict = action.payload;
      state.bulkEditInspectionItems = action.payload;
    },
  },
});

function getSettingsData() {
  return async (dispatch, getState) => {
    dispatch(settingsSlice.actions.updateLoading(true));

    const getDevicesRequest = fetch('/getDevices', {cache: 'no-store'});
    const getDatabaseUsersRequest = fetch('/settings/getDatabaseUsers', {cache: 'no-store'});
    const getSettingsGroupsRequest = fetch('/settings/getSettingsGroups', {cache: 'no-store'});
    const getVehiclesRequest = fetch('/getVehicles', {cache: 'no-store'});
    const getVehiclesUnfilteredRequest = fetch('/getVehiclesUnfiltered', {cache: 'no-store'});
    const getZonesRequest = fetch('/getZonesDataUnfiltered', {cache: 'no-store'});
    const getLabelsRequest = fetch('/getAssetLabels', {cache: 'no-store'});
    const getBtTagsRequest = fetch('/getBtTags', {cache: 'no-store'});
    const getBtHubsRequest = fetch('/getBtHubs', {cache: 'no-store'});
    const getInspectionItemsRequest = fetch('/getInspectionItems', {cache: 'no-store'});

    const [
      getDevicesResponse,
      getDatabaseUsersResponse,
      getVehiclesResponse,
      getVehiclesUnfilteredResponse,
      getZonesResponse,
      getLabelsResponse,
      getBtTagsResponse,
      getBtHubsResponse,
      getInspectionItemsResponse,
      getSettingsGroupsResponse,
    ] = await Promise.all([
      getDevicesRequest,
      getDatabaseUsersRequest,
      getVehiclesRequest,
      getVehiclesUnfilteredRequest,
      getZonesRequest,
      getLabelsRequest,
      getBtTagsRequest,
      getBtHubsRequest,
      getInspectionItemsRequest,
      getSettingsGroupsRequest,
    ]);

    const devices = await getDevicesResponse.json();
    const databaseUsers = await getDatabaseUsersResponse.json();
    const vehicles = await getVehiclesResponse.json();
    const vehiclesUnfiltered = await getVehiclesUnfilteredResponse.json();
    const zones = await getZonesResponse.json();
    const labels = await getLabelsResponse.json();
    const btTags = await getBtTagsResponse.json();
    const btHubs = await getBtHubsResponse.json();
    const inspectionItems = await getInspectionItemsResponse.json();
    const settingsGroups = await getSettingsGroupsResponse.json();

    databaseUsers.sort((a, b) => {
      // Move all archived users to the bottom
      if (a.archived && !b.archived) {
        return 1;
      }
      if (b.archived && !a.archived) {
        return -1;
      }

      // Pull all reports only account placeholders to bottom
      if (a.username.includes('REPORTS-ONLY') && !b.username.includes('REPORTS-ONLY')) {
        return 1;
      } else if (b.username.includes('REPORTS-ONLY') && !a.username.includes('REPORTS-ONLY')) {
        return -1;
      }

      // If status is equal, sort by email
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }

      // If status is equal, sort by user name
      if (a.username < b.username) {
        return -1;
      }
      if (a.username > b.username) {
        return 1;
      }

      return 0;
    });

    dispatch(settingsSlice.actions.updateDevices(devices));
    dispatch(settingsSlice.actions.updateDatabaseUsers(databaseUsers));
    dispatch(settingsSlice.actions.updateSettingsGroups(settingsGroups));
    dispatch(settingsSlice.actions.updateVehiclesData(vehicles));
    dispatch(settingsSlice.actions.updateVehiclesUnfilteredData(vehiclesUnfiltered));
    dispatch(settingsSlice.actions.updateZonesData(zones.data));
    dispatch(settingsSlice.actions.updateLabelsData(labels));
    dispatch(settingsSlice.actions.updateBtTagsData(btTags));
    dispatch(settingsSlice.actions.updateBtHubsData(btHubs));
    dispatch(settingsSlice.actions.updateInspectionItemsData(inspectionItems));
    dispatch(settingsSlice.actions.updateLoading(false));
    dispatch(settingsSlice.actions.updateTableLoading({table: 'assetLabels', status: false}));
    dispatch(settingsSlice.actions.updateTableLoading({table: 'inspectionItems', status: false}));
  };
}

function getTaskConfigsData() {
  return async (dispatch, getState) => {
    const getTaskConfigsRequest = fetch('/getTaskConfigs', {cache: 'no-store'});
    const getTaskConfigsResponse = await getTaskConfigsRequest;
    const taskConfigIdDict = await getTaskConfigsResponse.json();

    dispatch(settingsSlice.actions.updateTableLoading({table: 'task', status: false}));
    dispatch(settingsSlice.actions.updateTaskConfigDict(taskConfigIdDict));
  };
}

function getImplementsData() {
  return async (dispatch, getState) => {
    const getImplementsRequest = fetch('/getImplements', {cache: 'no-store'});

    const getImplementsResponse = await getImplementsRequest;
    const implementSNDict = await getImplementsResponse.json();

    dispatch(settingsSlice.actions.updateTableLoading({table: 'implements', status: false}));
    dispatch(settingsSlice.actions.updateImplementSNDict(implementSNDict));
  };
}

function getReportConfigsData() {
  return async (dispatch, getState) => {
    const getReportConfigsRequest = fetch('/settings/getReportConfigs', {cache: 'no-store'});

    const getReportConfigsResponse = await getReportConfigsRequest;
    const reportConfigsDict = await getReportConfigsResponse.json();

    dispatch(settingsSlice.actions.updateReportConfigsDict(reportConfigsDict));
  };
}

function getUpdatedVehicles() {
  return async (dispatch, getState) => {
    const getVehiclesRequest = fetch('/getVehicles', {cache: 'no-store'});

    const getVehiclesResponse = await getVehiclesRequest;
    const vehicles = await getVehiclesResponse.json();

    dispatch(settingsSlice.actions.updateTableLoading({table: 'vehicles', status: false}));
    dispatch(settingsSlice.actions.updateVehiclesData(vehicles));
  };
}

export {getSettingsData, getTaskConfigsData, getImplementsData, getReportConfigsData, getUpdatedVehicles};
export const {
  triggerGetSettingsData,
  updateLoading,
  updateTableLoading,
  updateSettingsGroups,
  updateDevices,
  setImplementsBulkEditMode,
  updateBulkEditVehicles,
  updateBulkEditImplements,
  initializeBulkEditImplements,
  initializeBulkEditVehicles,
  setImplementsBulkEditErrors,
  updateBulkEditTasks,
  initializeBulkEditTasks,
  setTasksBulkEditErrors,
  setTasksBulkEditMode,
  setAssetLabelsBulkEditErrors,
  setAssetLabelsBulkEditMode,
  initializeBulkEditAssetLabels,
  updateBulkEditAssetLabels,
  setVehiclesBulkEditErrors,
  initializeBulkEditInspectionItems,
  setInspectionItemsBulkEditMode,
  setInspectionItemsBulkEditErrors,
  updateBulkEditInspectionItems,
  updateLabelsData,
  updateInspectionItemsData,
} = settingsSlice.actions;
export default settingsSlice.reducer;
